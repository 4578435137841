import { Box, Button, Heading, Img, VStack } from "@chakra-ui/react";
import { useState } from "react";

export const MyImg = ({ src, onfs = false, link = null, ...props }) => {
    const [fs, setFs] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const openFs = () => {
        setFs(true);
    };
    const closeFs = () => setFs(false);
    return (
        <>
            {link ? (
                <a href={link} target="_blank" rel="noopener noreferrer">
                    <Img src={src} {...props} />
                </a>
            ) : (
                <Img
                    src={src}
                    {...props}
                    onClick={openFs}
                    style={loaded ? {} : { display: "none" }}
                    cursor={onfs ? "pointer" : "default"}
                    onLoad={() => setLoaded(true)}
                />
            )}
            {onfs && fs ? (
                <Box
                    width="100vw"
                    height="100vh"
                    left={0}
                    top={0}
                    position="fixed"
                    bg="blackAlpha.700"
                    onClick={closeFs}
                    display="flex"
                    justifyContent={"center"}
                    alignItems="center"
                    zIndex={999}
                >
                    <VStack
                        position="relative"
                        maxW="90vw"
                        maxH="90vh"
                        bg="white"
                        spacing={0}
                    >
                        <Img
                            src={src}
                            onClick={closeFs}
                            maxW="90vw"
                            maxH="90vh"
                        />
                        <Heading
                            w="100%"
                            _hover={{
                                opacity: 0,
                            }}
                            textAlign="center"
                            color="black"
                            size="sm"
                            bg="whiteAlpha.500"
                            borderBottomRadius="0.5em"
                        >
                            Close by clicking anywhere
                        </Heading>
                        <Button
                            bgColor="black"
                            color="white"
                            position="absolute"
                            top={5}
                            right={10}
                        >
                            X
                        </Button>
                    </VStack>
                </Box>
            ) : null}
        </>
    );
};
