import { VStack } from "@chakra-ui/react";
import { BriefSlider } from "../../components";
import earthBG from "../../assets/earth_2024.jpg";
import * as colors from "../../data/colors";
import { useGetDataQuery } from "../../api/astrosApi";

export const ServicesPage = (props) => {
    const { data = [] } = useGetDataQuery({ resource: "services" });
    const services = data
        ?.filter((p) => p.visible)
        .sort((p1, p2) => p1.priority - p2.priority);
    return (
        <VStack
            width="100%"
            {...props}
            listStylePosition={"inside"}
            flexGrow={1}
            overflow="auto"
            rowGap={10}
            color={colors.textBlue}
            backgroundRepeat="no-repeat"
            backgroundPosition={"-400px"}
            backgroundImage={earthBG}
            bgColor={"white"}
            backgroundSize={"contain"}
        >
            <BriefSlider arrayItem={services} title="Services" contact={true} />
        </VStack>
    );
};
