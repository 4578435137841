import { Box, HStack, VStack } from "@chakra-ui/react";
import * as colors from "../../data/colors";

export const DotIndicator = ({
    vertical = false,
    spacing = 5,
    numberOfDots,
    currentDotIndex,
    onClickChange,
    ...props
}) => {
    const Stack = vertical ? VStack : HStack;

    const dots = [];
    for (let i = 0; i < numberOfDots; i++) {
        dots.push(
            <Box
                key={i}
                backgroundColor={i !== currentDotIndex ? "" : "black"}
                onClick={() => onClickChange(i)}
                borderColor={colors.neonblue}
                _hover={{
                    transition: "0.5s",
                    backgroundColor: colors.bgblue1,
                    borderColor: colors.bgblue1,
                }}
                minW="20px"
                minH="20px"
                border="2px solid"
                borderRadius="100%"
                cursor="pointer"
            />
        );
    }

    return (
        <Stack className="dot-indicator" {...props}>
            <HStack spacing={spacing} bg="white">
                {dots}
            </HStack>
        </Stack>
    );
};
