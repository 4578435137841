import {
    Box,
    Flex,
    HStack,
    Heading,
    Img,
    Link,
    Spinner,
    Stack,
    Text,
    VStack,
    useBreakpointValue,
} from "@chakra-ui/react";
import { NavLink as ReactLink } from "react-router-dom";
import { DotIndicator, MyImg } from "../../components";
import { useState, useEffect } from "react";
import LeftArrow from "../../assets/arrow_gray_left.svg";
import RightArrow from "../../assets/arrow_gray_right.svg";
import LeftColArrow from "../../assets/arrow_colored_left.svg";
import RightColArrow from "../../assets/arrow_colored_right.svg";
import { useLocation } from "react-router-dom";
import { baseURL } from "../../api/astrosApi";
import HtmlToReact from "html-to-react";

export const BriefSlider = ({
    arrayItem,
    title,
    effects = null,
    contact = false,
    ...props
}) => {
    const location = useLocation();

    const [page, setPage] = useState(location.state || 0);
    const item = arrayItem[page];

    const [parser] = useState(new HtmlToReact.Parser());
    let bodytext;
    try {
        bodytext = parser.parse(item.bodytext);
    } catch (err) {
        bodytext = item?.bodytext;
    }
    useEffect(() => {
        if (!item) return;
        if (effects) {
            effects.forEach((effect) =>
                effect["function"](effect.format(item[effect.property]))
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item]);

    function nextPage() {
        setPage(Math.min(page + 1, arrayItem.length - 1));
    }
    function previousPage() {
        setPage(Math.max(0, page - 1));
    }

    const showArrows = useBreakpointValue({
        base: false,
        md: true,
    });

    // eslint-disable-next-line eqeqeq
    if (!item) {
        return <Spinner />;
    }
    let itemtitle, bigtitle;
    if (title == null) {
        bigtitle = item.title;
    } else {
        bigtitle = title;
        itemtitle = item.title;
    }
    return (
        <VStack
            w="100%"
            p={0}
            flex={1}
            h="100%"
            overflow={"auto"}
            alignItems={"center"}
            bgColor="rgba(255, 255, 255, 0.8)"
        >
            <Heading userSelect="none" pl="1em" pr="1em" mb={2} mt={4}>
                {bigtitle}
            </Heading>
            <DotIndicator
                numberOfDots={arrayItem.length}
                currentDotIndex={page}
                onClickChange={setPage}
                p={2}
                pb={5}
                w="100%"
                justify="center"
                position="sticky"
                top={0}
            />
            <VStack alignItems={["center"]} w="100%">
                {itemtitle != null ? (
                    <Heading
                        textAlign="left"
                        m={0}
                        paddingLeft={[6, "1%", "10.2%", "8.1%"]}
                        size="md"
                        w={["100%", "80%", "75%", "60%"]}
                        // boxSizing="border-box"
                        // ml={[0, 0, "5em"]}
                    >
                        {itemtitle}
                    </Heading>
                ) : null}
                <HStack
                    w={["100%", "80%", "75%", "60%"]}
                    height="100%"
                    borderRadius="10px"
                    h="min-content"
                    alignItems={["flex-start", "center"]}
                    spacing={0}
                    p={["1em", 0]}
                    justify="center"
                    boxSizing="border-box"
                    flex={1}
                    overflow={"auto"}
                >
                    {!showArrows || (
                        <Img
                            src={page <= 0 ? LeftArrow : LeftColArrow}
                            onClick={previousPage}
                            zIndex={99}
                            minW="5em"
                            minH="5em"
                            maxW="5em"
                            maxH="5em"
                            userSelect="none"
                            position="fixed"
                            left={[0, 0, "2%", "15%"]}
                            top="calc(50% - 5em)"
                        />
                    )}
                    <Stack
                        w={["100%", "100%", "74%"]}
                        flexWrap={"wrap"}
                        justify="center"
                        alignContent={"center"}
                        flexDir={{
                            base: "column",
                            xl: "row",
                        }}
                    >
                        <Box
                            textAlign={"left"}
                            alignItems={"flex-start"}
                            p={2}
                            width={{
                                base: "100%",
                                xl: "49%",
                            }}
                        >
                            {bodytext}
                        </Box>
                        <Flex
                            justify="center"
                            alignItems="flex-start"
                            flex={1}
                            width={{
                                base: "100%",
                                xl: "49%",
                            }}
                        >
                            <MyImg
                                src={baseURL + "assets/" + item?.path}
                                onfs={true}
                                flexBasis="content"
                                objectFit="contain"
                                minW="200px"
                                maxH="350px"
                                borderRadius="1em"
                            />
                        </Flex>
                    </Stack>
                    {!showArrows || (
                        <Img
                            src={
                                page >= arrayItem.length - 1
                                    ? RightArrow
                                    : RightColArrow
                            }
                            onClick={nextPage}
                            zIndex={99}
                            minW="5em"
                            minH="5em"
                            maxW="5em"
                            maxH="5em"
                            right={[0, 0, "2%", "15%"]}
                            top="calc(50% - 5em)"
                            position="fixed"
                            userSelect="none"
                        />
                    )}
                </HStack>
                {!contact || (
                    <Text
                        fontSize={"1.1em"}
                        userSelect={"none"}
                        position="sticky"
                        bottom="0"
                        bg="white"
                        borderTopRadius="1em"
                        p={2}
                    >
                        For a price quotation, please contact us via the 👉{" "}
                        <Link
                            as={ReactLink}
                            _hover={{
                                textDecoration: "underline",
                            }}
                            fontWeight={"bold"}
                            to="/contact"
                        >
                            Contact Form
                        </Link>{" "}
                    </Text>
                )}
            </VStack>
            {props.children}
        </VStack>
    );
};
