import { useState } from "react";
import { BriefSlider } from "../briefslider";
import { MyMap } from "../map/map.js";
import { Flex } from "@chakra-ui/react";
import { useGetDataQuery } from "../../api/astrosApi/index.js";

export const Sensors = () => {
    const { data = [] } = useGetDataQuery({ resource: "sensors" });
    const sensors = data
        .filter((s) => s.visible)
        .sort((s1, s2) => s1.priority - s2.priority);

    const [center, setCenter] = useState({
        lat: 48.149022,
        lng: 17.073269,
    });
    const [zoomSettings, adjustZoom] = useState({
        zoom: 4,
        maxZoom: 4,
    });
    return (
        <BriefSlider
            arrayItem={sensors}
            title="Sensors"
            effects={[
                {
                    function: setCenter,
                    property: "location",
                    format: (loc) => {
                        const [lat, lng] = loc.split(" ");
                        return { lat: lat, lng: lng };
                    },
                },
                {
                    function: adjustZoom,
                    property: "zoom",
                    format: (val) => ({ zoom: val, maxZoom: val }),
                },
            ]}
            adjustZoom={adjustZoom}
        >
            <Flex
                flexGrow={1}
                minW={["100%", "80%", "75%", "60%"]}
                w={["100%", "80%", "75%", "60%"]}
                minH="20em"
            >
                <MyMap
                    center={center}
                    maxZoom={zoomSettings.zoom}
                    zoom={zoomSettings.maxZoom}
                    tileVariant="light"
                />
            </Flex>
        </BriefSlider>
    );
};
